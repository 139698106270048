<div class="metabase-embed-container" *ngIf="metabaseUrl">
  <iframe
    class="full-screen-iframe"
    width="800"
    height="600"
    [src]="metabaseUrl"
    frameborder="0"
    allowtransparency
  ></iframe>
</div>
