import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-metabase-embed',
  templateUrl: './metabase-embed.component.html',
  styleUrls: ['./metabase-embed.component.css'],
})
export class MetabaseEmbedComponent implements OnInit {
  metabaseUrl: SafeResourceUrl = '';
  userInfo: any;
  userOrg: string = '';

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private keycloakService: KeycloakService
  ) {}

  async ngOnInit() {
    if (await this.keycloakService.isLoggedIn()) {
      this.userInfo = await this.keycloakService.loadUserProfile();
      this.userOrg = this.userInfo.attributes.type[0];
    }

    let params = new HttpParams().set('organization', this.userOrg);

    this.http
      .get<{ url: string }>(
        `${environment.metabaseApiUrl}/api/get-metabase-embed-url`,
        { params }
      )
      .subscribe((response) => {
        this.metabaseUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          response.url
        );
      });
  }

  async logout() {
    await this.keycloakService.logout();
  }
}
