import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'public_metabase_embeddings_platform';

  constructor(private keycloakService: KeycloakService) {}

  async logout() {
    await this.keycloakService.logout();
  }
}
