import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { KeycloakInitService } from './services/keycloak-init.service';

import { AppComponent } from './app.component';
import { MetabaseEmbedComponent } from './metabase-embed/metabase-embed.component';
import { SafeUrlPipe } from './safe-url.pipe';

export function initializer(keycloakInitService: KeycloakInitService) {
  return () => keycloakInitService.init();
}

@NgModule({
  declarations: [AppComponent, MetabaseEmbedComponent, SafeUrlPipe],
  imports: [BrowserModule, HttpClientModule, KeycloakAngularModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [KeycloakInitService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
